<!--使用初始密码进入提示页面-->
<template>
    <div class="frist-set-pwd">
        <i class="iconfont iconcuowu"></i>
        <h2>未重新设置密码</h2>
        <p>为了您的账户安全，首次登录需要重新设置密码</p>
        <a @click="toSetPwd">去设置</a>
    </div>
</template>

<script>
export default {
    name: 'frist-set-pwd',
    methods: {
        // 去设置密码
        toSetPwd() {
            this.$toast({
                title: false,
                type: 'eject',
                width: '9rem',
                height: '6.2rem',
                t_url: 'userinfo/edit',
                extr: {
                    fristEdit: true,
                },
            });
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.frist-set-pwd{
    height 100%;
    display flex
    justify-content center;
    align-items center;
    flex-direction column;
    position relative
    z-index 99;
    i{
        color #E02020
        font-size 0.74rem;
    }
    h2{
        margin-top 0.25rem;
        font-size 0.24rem;
        color #333;
    }
    p{
        margin-top 0.2rem;
        font-size 0.16rem;
        color #979EA7;
    }
    a{
        margin-top 0.5rem
        display flex
        justify-content center
        align-items center
        width: 2.6rem;
        height: 0.5rem;
        background: #1577D2;
        border-radius: 1px;
        font-size 0.2rem;
        color #fff;
        cursor pointer;
    }
}
</style>
